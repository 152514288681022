export const BASEURL = '/';
export const SIGNUP = '/signup';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forget-password';
export const DASHBOARD = '/dashboard';

export const PROJECT_BASE_URL = '/projects';
export const PROJECT_DETAILS = `${PROJECT_BASE_URL}`;
export const ADD_PROJECT = `${PROJECT_BASE_URL}/add-project`;
export const EDIT_PROJECT = `${PROJECT_BASE_URL}/edit-project`;

export const EMPLOYEE_BASE_URL = '/employee';
export const EMPLOYEE_TABLE = `${EMPLOYEE_BASE_URL}/table`;
export const EMPLOYEE_LIST = `${EMPLOYEE_BASE_URL}`;
export const EMPLOYEE_ADD = `${EMPLOYEE_BASE_URL}/add`;
export const EMPLOYEE_DETAILS = `${EMPLOYEE_BASE_URL}/details/:id`;
export const EMPLOYEE_HANDBOOK = `${EMPLOYEE_BASE_URL}/handbook`;

export const EMPLOYEE_OFFBOARDING_DASHBOARD = `${EMPLOYEE_BASE_URL}/off-boarding/dashboard`;
export const EMPLOYEE_OFFBOARDING_DETAILS = `${EMPLOYEE_BASE_URL}/off-boarding/details`;
export const EMPLOYEE_OFFBOARDING_FORM = `${EMPLOYEE_BASE_URL}/off-boarding/form`;

export const EMPLOYEE_REIMBURSEMENT_TAB = `${EMPLOYEE_BASE_URL}/reimbursments`;
export const EMPLOYEE_REIMBURSEMENT_DASHBOARD = `${EMPLOYEE_REIMBURSEMENT_TAB}/dashboard`;
export const REVIEW_REQUEST = '/reimbursments/review-request';
export const REIMBURSEMENT_REQUEST_FORM = `${EMPLOYEE_BASE_URL}/reimbursments/request-new`;
export const EMPLOYEE_REIMBURSEMENT_VIEW_REQUESTS = `${EMPLOYEE_BASE_URL}/reimbursments/view`;
export const EMPLOYEE_REIMBURSEMENT_PAST_REQUESTS = `${EMPLOYEE_BASE_URL}/reimbursments/closed`;
export const EMPLOYEE_REIMBURSEMENT_PENDING_REQUESTS = `${EMPLOYEE_BASE_URL}/reimbursments/pending`;

export const EMPLOYEE_PERFORMANCE_MANAGEMENT = `${EMPLOYEE_BASE_URL}/performance`;
export const EMPLOYEE_PERFORMANCE_PAST_APPRAISAL_DETAILS = `${EMPLOYEE_BASE_URL}/performance/appraisals/details`;
export const EMPLOYEE_PERFORMANCE_NEW_APPRAISAL = `${EMPLOYEE_BASE_URL}/performance/appraisals/new`;
export const EMPLOYEE_PERFORMANCE_UPCOMING_APPRAISAL = `${EMPLOYEE_BASE_URL}/performance/appraisals/upcoming`;
export const APPRAISAL_FORM = `${EMPLOYEE_BASE_URL}/performance/appraisalform`;

export const EMPLOYEE_PERFORMANCE_EVALUATION_FORM =
  '/performance-evaluation-form';
export const EMPLOYEE_PEER_REVIEW_FORM = '/peer-review-form';

export const EMPLOYEE_ATTENDANCE_MANAGEMENT = `/attendance`;
export const EMPLOYEE_VIEW_ATTENDANCE = `/attendance/:id/:firstName/:lastName`;
export const EMPLOYEE_ATTENDANCE = `/attendance/employeeAttendance`;
// export const EMPLOYEE_VIEW_ATTENDANCE = `/attendance`;

export const EMPLOYEE_LEAVE_MANAGEMENT = `/leaves`;
export const EMPLOYEE_LEAVE_REQUESTS = `/leaves/requests`;
export const EMPLOYEE_VIEW_LEAVES = `/leaves/view`;
export const EMPLOYEE_SUBMIT_LEAVE = `/leaves/view/submit-new`;
export const EMPLOYEE_LEAVE_RECORD = `/leaves/details`;
export const EMPLOYEE_REVIEW_LEAVE = `/leaves/review-leave`;

const RESOURCES_BASE_URL = '/resources';
export const RESOURCES_LIST = `${RESOURCES_BASE_URL}/list`;
export const RESOURCES_ASSIGNED = `${RESOURCES_BASE_URL}/assigned`;

export const FILE_DISPLAY = '/view-file';
export const MY_PROFILE = '/my-profile';

export const PAST_REQUESTS = '/past-requests';
export const PENDING_REQUESTS = '/pending-requests';
export const VIEW_COMPANY_RESOURCE = '/viewcompanyresource';
export const VIEW_PAST_REQUEST = '/view-past-requests';
export const VIEW_REQUESTS = '/employee-past-requests';

export const WORKINGLOG = '/work-logging-form';
export const WORK_LOG_HISTORY = '/work-log-history';
export const ATTENDANCE_DETAILS = '/attendance-details';
export const COMPANY_RESOURCE_LIST = '/companyresourcelist';
export const CHANGE_PASSWORD = '/change-password';

export const INVOICE_BASE_URL = '/invoices';
export const CREATE_NEW_INVOICE = `${INVOICE_BASE_URL}/new-invoice`;
export const INVOICE_DETAILS = `${INVOICE_BASE_URL}/details/:id`;

export const ERROR_PAGE = '/*';
export const SET_PASSWORD = '/set-password';
