import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

// Async thunk for fetching subscription data
export const getSubscriptionData = createAsyncThunk(
  'subscription/getSubscriptionData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/subscription'); // Adjust the endpoint as needed
      console.log(response.data);
      return response.data;
    } catch (error) {
      // Log the error to help with debugging
      console.error('Error fetching subscription data:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    data: null,
    loading: false,
    done: false,
    error: null, // Use null instead of false for better error handling
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionData.pending, (state) => {
        state.loading = true;
        state.done = false;
        state.error = null; // Reset error state
      })
      .addCase(getSubscriptionData.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.data = action.payload?.data; // Adjust based on actual response structure
      })
      .addCase(getSubscriptionData.rejected, (state, action) => {
        state.loading = false;
        state.done = false;
        state.error = action.payload || 'Failed to fetch subscription data'; // Handle error
      });
  },
});

export default subscriptionSlice.reducer;
