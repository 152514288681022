import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import ProjectCard from './ProjectCard';
import AddUpdateProject from './AddUpdateProject';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import { getSubscriptionData } from '../../Redux/slices/subscriptionSlice';
import PlaceholderForEmptyState from '../../Components/MiscComponents/PlaceholderEmptyTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { toast } from 'react-toastify';

function ProjectList() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);

  const loading = useSelector((state) => state.projects.loading);
  const subscriptionData = useSelector((state) => state.subscription.data);
  const projectsResponse = useSelector((state) => state.projects.allProjects);
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );

  const isAdminLoggedIn = userRole && userRole.toLowerCase() === 'admin';
  const projectCount = projectsResponse.length; // Assuming you get all projects in this array
  const maxProjects = subscriptionData?.maxProjects;

  useEffect(() => {
    dispatch(getSubscriptionData());
    dispatch(getAllProjects());
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(
      search.length === 0
        ? projectsResponse
        : projectsResponse.filter((project) =>
            project.name.toLowerCase().includes(search.toLowerCase())
          )
    );
  }, [projectsResponse, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenAddUpdateModal = () => {
    if (projectCount >= maxProjects) {
      toast.error(
        'Project limit reached. Please upgrade your plan to add more projects.'
      );
      return;
    }
    setIsAddUpdateModalOpen(true);
  };

  const handleCloseAddUpdateModal = () => {
    setIsAddUpdateModalOpen(false);
  };

  const handleClearFilters = () => {
    setSearch('');
  };

  return (
    <ContentWrapper
      heading="Projects"
      isLoading={loading}
      addButtonText={isAdminLoggedIn ? 'Add Project' : ''}
      addButtonOnClick={isAdminLoggedIn ? handleOpenAddUpdateModal : undefined}
      filters={[
        {
          type: 'input',
          value: search,
          onChange: handleSearch,
          placeholder: 'Search Projects...',
        },
      ]}
      onClearFilters={handleClearFilters}
    >
      {filteredItems.length > 0 ? (
        <Box
          display="grid"
          gridTemplateColumns={{
            sm: '1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
          }}
          gap="24px"
          mt="44px"
        >
          {filteredItems.map((project, idx) => (
            <Box
              className="slide-up"
              key={project.id}
              sx={{ animationDelay: `${idx / 20}s` }}
            >
              <ProjectCard project={project} />
            </Box>
          ))}
        </Box>
      ) : (
        <PlaceholderForEmptyState message={'No projects found'} />
      )}

      {isAddUpdateModalOpen && (
        <AddUpdateProject onClose={handleCloseAddUpdateModal} />
      )}
    </ContentWrapper>
  );
}

export default ProjectList;
