import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logger } from '../../Services/loggerService';

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

// Async Thunk for fetching all users with pagination and sorting
export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (
    { filters = {}, page = 1, limit = 10, sort = '-createdAt' },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        ...filters,
        ...(page !== undefined && { page }), // Only include if page is provided
        ...(limit !== undefined && { limit }), // Only include if limit is provided
        sort,
      };
      const response = await axios.get('/v1/users', {
        params,
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Get all users', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for fetching user details by ID
export const getUserById = createAsyncThunk(
  'users/getUserById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/users/${userId}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Get user by id', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for creating a new user
export const createNewUser = createAsyncThunk(
  'users/createNewUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/users', userData, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Create new user', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for updating a user
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/v1/users/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Update user', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for updating user profile
export const updateProfile = createAsyncThunk(
  'users/updateProfile',
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/v1/users/update-profile`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Update user profile', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for updating user password
export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/v1/user/update-password', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Update user password', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for fetching user organizations
export const getUserOrg = createAsyncThunk(
  'users/getUserOrg',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/users/get-user-org', {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Get user org', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for setting active organization
export const setActiveOrg = createAsyncThunk(
  'users/setActiveOrg',
  async (organizationId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/v1/users/set-active-org',
        { organizationId },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      return response.data;
    } catch (error) {
      logger.error('Set active org', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async Thunk for fetching user attendance
export const getUserAttendance = createAsyncThunk(
  'users/getUserAttendance',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/users/${userId}/attendance`, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      logger.error('Get user attendance', error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  allUsers: [],
  selectedUser: null,
  loading: false,
  done: false,
  error: null,
  message: '',
  metadata: {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    setCheckInTime: (state, action) => {
      state.checkInTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allUsers = action.payload.data;
        state.metadata = action.payload.metadata; // Update state with API response metadata
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // TODO: cases for other async thunks as needed
      .addCase(getUserById.fulfilled, (state, action) => {
        state.selectedUser = action.payload.data;
      })
      .addCase(getUserOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.userOrganizations = action.payload.data; // TODO: Adjust response structure
      })
      .addCase(getUserOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle pending and rejected cases for setting active organization
      .addCase(setActiveOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(setActiveOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.activeOrganization = action.payload.organizationId; // TODO: Adjust response structure
        state.message = 'Active organization set successfully.';
      })
      .addCase(setActiveOrg.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle pending and rejected cases for fetching user attendance
      .addCase(getUserAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.userAttendance = action.payload.attendance;
      })
      .addCase(getUserAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNewUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetUserState, setCheckInTime } = usersSlice.actions;
export default usersSlice.reducer;
