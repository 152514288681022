import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks
export const createOrganization = createAsyncThunk(
  'organization/createOrganization',
  async (organizationData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/v1/organization/create',
        organizationData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllOrganizations = createAsyncThunk(
  'organization/getAllOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/organization');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrganizationById = createAsyncThunk(
  'organization/getOrganizationById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/organization/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'organization/updateOrganization',
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/v1/organization/${id}`, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/v1/organization/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const organizationSlice = createSlice({
  name: 'organizations',
  initialState: {
    list: [],
    currentOrganization: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create organization
      .addCase(createOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
        state.currentOrganization = action.payload;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch all organizations
      .addCase(getAllOrganizations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(getAllOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch organization by ID
      .addCase(getOrganizationById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrganizationById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrganization = action.payload;
      })
      .addCase(getOrganizationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update organization
      .addCase(updateOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (org) => org._id === action.payload._id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        state.currentOrganization = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete organization
      .addCase(deleteOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter((org) => org._id !== action.payload);
        if (
          state.currentOrganization &&
          state.currentOrganization._id === action.payload
        ) {
          state.currentOrganization = null;
        }
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default organizationSlice.reducer;
